<template>
    <div class="d-flex flex-column align-items-center">
        <h3 class="text-success title-success-verify">
        <strong class="d-flex align-items-center">
            <feather-icon size="28" icon="CheckCircleIcon"/> 
            <span class="text-center ml-50">{{ title }}</span>
        </strong>
        </h3>
        <div v-if="$slots.default">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'text-success-meli',
  props: ['title']
}
</script>
<style>

@media (max-width: 870px) { 
    .title-success-verify span{
        font-size: 18px;
    }
}

</style>